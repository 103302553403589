import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import { authSlice } from "src/modules/Auth/services/authSlice";

import {
  merchantUsersSlice,
  overviewSlice,
  merchantSlice,
  referralManagementSlice,
  premblyCertifcateSlice,
  backgroundCheckSlice,
  notificationSlice,
  identityRadarSlice
  
} from "src/modules/Admin/services";

import { accountReducers } from "src/features/index";
import { auditLogsslice } from "src/modules/Admin/services/AuditLogsSlice";
import { rolesAndPermissionSlice } from "src/modules/Admin/services/RolesAndPermissionSlice";

export const store = configureStore({
  reducer: {
    [authSlice.reducerPath]: authSlice.reducer,
    [merchantUsersSlice.reducerPath]: merchantUsersSlice.reducer,
    [overviewSlice.reducerPath]: overviewSlice.reducer,
    [merchantSlice.reducerPath]: merchantSlice.reducer,
    [referralManagementSlice.reducerPath]: referralManagementSlice.reducer,
    [premblyCertifcateSlice.reducerPath]: premblyCertifcateSlice.reducer,
    [backgroundCheckSlice.reducerPath]: backgroundCheckSlice.reducer,
    [notificationSlice.reducerPath]: notificationSlice.reducer,
    [identityRadarSlice.reducerPath]: identityRadarSlice.reducer,
    [auditLogsslice.reducerPath]:auditLogsslice.reducer,
    [rolesAndPermissionSlice.reducerPath]:rolesAndPermissionSlice.reducer,
    account: accountReducers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(authSlice.middleware)
      .concat(merchantUsersSlice.middleware)
      .concat(auditLogsslice.middleware)
      .concat(rolesAndPermissionSlice.middleware)
      .concat(overviewSlice.middleware)
      .concat(merchantSlice.middleware)
      .concat(referralManagementSlice.middleware)
      .concat(premblyCertifcateSlice.middleware)
      .concat(backgroundCheckSlice.middleware)
      .concat(notificationSlice.middleware)
      .concat(identityRadarSlice.middleware)
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
